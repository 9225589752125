import React from "react";
import Image from "react-image-webp";
import { useTranslation } from "react-i18next";

import NavigationLink from "../../i18n/components/NavigationLink";
import CartBoxImg from "../../assets/images/two-cardboard-boxes-cart.png";
import CartBoxImgWebP from "../../assets/images/webp/two-cardboard-boxes-cart.webp";
import "./style.css";

const WpoFeatures = () => {
  const { t } = useTranslation();
  return (
    <div className="wpo-features-area">
      <div className="container">
        <div className="wpo-section-title text-center">
          <span>{t("pricing-page.box.subtitle")}</span>
          <h2>{t("pricing-page.box.title")}</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="wpo-features-item-2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="wpo-feature-wrap">
                  <div className="wpo-features-icon2">
                    <i className="fi flaticon-truck-2"></i>
                  </div>
                  <div className="wpo-features-text">
                    <h3>{t("services.express-transport.title")}</h3>
                    <p>{t("services.express-transport.description")}</p>
                    {/* <h3>First Air Freight</h3>
                  <p>
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text,
                  </p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="wpo-feature-wrap">
                  <div className="feature-icon3">
                    <i className="fi flaticon-trolley"></i>
                  </div>
                  <div className="wpo-features-text">
                    <h3>{t("services.warehousing.title")}</h3>
                    <p>{t("services.warehousing.description")}</p>
                    {/*                 
                  <h3>First Ground Shipping</h3>
                  <p>
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text,
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="wpo-features-item">
              <div className="wpo-feature-img">
                <Image
                  src={CartBoxImg}
                  webp={CartBoxImgWebP}
                  alt="Pricing Cart box"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="wpo-features-item">
              <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="wpo-feature-wrap">
                  <div className="wpo-features-icon">
                    <i className="fi flaticon-truck-2"></i>
                  </div>
                  <div className="wpo-features-text">
                    <h3>{t("services.packaging.title")}</h3>
                    <p>{t("services.packaging.description")}</p>
                    {/* <h3>Quickest Cargo</h3>
                  <p>
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text,
                  </p> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="wpo-feature-wrap">
                <div className="wpo-features-icon">
                  <i className="fi flaticon-truck-1"></i>
                </div>
                <div className="wpo-features-text">
                  <h3>Timely Delivery</h3>
                  <p>
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text,
                  </p>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <div className="wpo-pricing-footer">
          <div className="btns">
            <div className="btn-style text-center">
              <NavigationLink to="ContactPage">
                {t("pricing-page.button.contact-us")}
              </NavigationLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WpoFeatures;

export const subjects = [
  {
    option: "contact-page.form.subject.select.subject",
    value: "",
    disabled: true,
  },
  {
    option: "contact-page.form.subject.select.comercial",
    value: "Comercial",
    disabled: false,
  },
  {
    option: "contact-page.form.subject.select.general",
    value: "Geral",
    disabled: false,
  },
  {
    option: "contact-page.form.subject.select.pricing",
    value: "Orçamento",
    disabled: false,
  },
  {
    option: "contact-page.form.subject.select.other.subject",
    value: "other.subject",
    disabled: false,
  },
];

import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar";
import Breadcumb from "../../components/breadcumb";
import AboutSection2 from "../../components/about2";
import Mission1 from "../../components/Mission1";
import FooterSection from "../../components/Footer";

const Aboutpage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <Breadcumb
        backgroundClassName="about"
        title={t("about-us-page.title")}
        subtitle={t("about-us-page.subtitle")}
      />
      <AboutSection2 />
      <Mission1 />
      <FooterSection />
    </div>
  );
};

export default Aboutpage;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as emailjs from "emailjs-com";
import Alert from "react-bootstrap/Alert";

import { subjects } from "../../const/app-subjects";

const ContactForm2 = () => {
  const { t } = useTranslation();

  const [mailInfo, setMailInfo] = useState({
    mailSend: false,
    mailError: false,
  });

  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    errors: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setEmailData({
      ...emailData,
      [name]: value,
      errors: {
        ...emailData.errors,
        [name]: "",
      },
    });
  };

  const validateForm = () => {
    let errors = {};
    let formatIsValid = true;

    const { name, email, subject, message } = emailData;

    if (name.trim() === "" || name.trim().length < 3) {
      errors.name = t("contact-page.form.error.name");
      formatIsValid = false;
    }

    if (subject.trim() === "" || subject.trim().length < 3) {
      errors.subject = t("contact-page.form.error.subject");
      formatIsValid = false;
    }

    if (email.trim() === "" || email.trim().length < 3) {
      errors.email = t("contact-page.form.error.email");
      formatIsValid = false;
    }

    let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!pattern.test(email)) {
      errors.email = t("contact-page.form.error.email");
      formatIsValid = false;
    }

    if (message.trim() === "" || message.trim().length < 10) {
      errors.message = t("contact-page.form.error.message");
      formatIsValid = false;
    }

    setEmailData({
      ...emailData,
      errors,
    });
    return formatIsValid;
  };

  const sendMessage = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const { name, email, subject, message } = emailData;

    var templateParams = {
      from_email: email,
      from_name: name,
      reply_to: email,
      from_subject: subject,
      message_html: message,
      to_name: "RMA-Transportes",
    };

    emailjs.send("service_u2cclo8", "template_sinkl5o", templateParams, "Cvc0d8iZtOGIc1yF-").then(
      (response) => {
        setMailInfo({ mailSend: true, mailError: false });
        
        // Clear form
        setEmailData({
          name: "",
          email: "",
          subject: "",
          message: "",
          errors: {
            name: "",
            email: "",
            subject: "",
            message: "",
          },
        });
      },
      (err) => {
        setMailInfo({ mailSend: false, mailError: true });
      }
    );
  };

  const successMessage = () => {
    const { mailSend, mailError } = mailInfo;

    if (mailSend) {
      return (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-success"
              dangerouslySetInnerHTML={{
                __html: t("contact-page.form.emailsend.success"),
              }}
            ></div>
          </div>
        </div>
      );
    }

    if (mailError) {
      return (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-danger"
              dangerouslySetInnerHTML={{
                __html: t("contact-page.form.emailsend.error"),
              }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <form>
        <div className="contact-form form-style row">
          <div className="col-12 col-lg-6">
            <input
              required
              placeholder={t("contact-page.form.name")}
              value={emailData.name}
              onChange={handleInputChange}
              type="text"
              name="name"
            />
            {emailData.errors.name && (
              <Alert variant={"danger"}>{emailData.errors.name}</Alert>
            )}
          </div>
          <div className="col-12  col-lg-6">
            <input
              required
              placeholder={t("contact-page.form.email")}
              value={emailData.email}
              onChange={handleInputChange}
              type="email"
              name="email"
            />
            {emailData.errors.email && (
              <Alert variant={"danger"}>{emailData.errors.email}</Alert>
            )}
          </div>
          <div className="col-12 col-sm-12">
            <select
              className="form-control"
              name="subject"
              value={emailData.subject}
              onChange={handleInputChange}
            >
              {subjects.map((subject, index) => {
                return (
                  <option
                    key={`option-${index}`}
                    disabled={subject.disabled}
                    value={subject.value}
                  >
                    {t(subject.option)}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12 col-sm-12">
            <textarea
              required
              placeholder={t("contact-page.form.message")}
              defaultValue={emailData.message}
              autoComplete={"off"}
              name="message"
              onChange={handleInputChange}
            />
            {emailData.errors.message && (
              <Alert variant={"danger"}>{emailData.errors.message}</Alert>
            )}
          </div>
          <div className="col-12">
            <button type="submit" className="theme-btn" onClick={sendMessage}>
              {t("contact-page.form.submit")}
            </button>
          </div>
        </div>
      </form>
      {successMessage()}
    </>
  );
};

export default ContactForm2;

import React from "react";
import { useTranslation } from "react-i18next";

import { LanguageSwitcher } from "../../i18n";
import NavigationLink from "../../i18n/components/NavigationLink";
import MobileMenu from "../../components/MobileMenu";
import logo from "../../images/logo/logo.png";
import { menus } from "../../const";
import "./style.css";

const Header = () => {
  const { t } = useTranslation();
  return (
    <header>
      <div className="header-style-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-10 col-sm-10 col-8 col-t">
              <div className="logo">
                <NavigationLink to="MainPage">
                  <img src={logo} alt={t("menu.home")} />
                </NavigationLink>
              </div>
            </div>
            <div className="col-lg-9 d-none d-lg-block col-m">
              <div className="main-menu">
                <nav className="nav_mobile_menu">
                  <ul>
                    {menus.map((item, index) => (
                      <li className="active" key={`menu-${item.id}-${index}`}>
                        <NavigationLink to={item.link}>
                          {t(item.title)}
                        </NavigationLink>
                      </li>
                    ))}
                    <LanguageSwitcher />
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 col-2">
              <MobileMenu menus={menus} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

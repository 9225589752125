import React from "react";
import { useTranslation } from "react-i18next";

// import notFoundImg from "../../images/not-found.jpg";

const NotFoundSection = () => {
  const { t } = useTranslation();
  return (
    <div className="blog-page-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="blog-left-bar">
              <div className="blog-item text-center">
                {/* <h2>{t(`not-found-page.title`)}</h2> */}
                {/* <div className="blog-img">
                  <img src={notFoundImg} alt="Not Found" />
                </div> */}
                <div className="blog-content-2 text-center">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`not-found-page.description`),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundSection;

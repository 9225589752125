import React from "react";

// components
import Navbar from "../../components/Navbar";
import SimpleSlider from "../../components/hero";
import AboutSection from "../../components/about";
import ServiceArea from "../../components/ServiceArea";
import FooterSection from "../../components/Footer";

const Homepage = () => (
  <>
    <Navbar />
    <SimpleSlider />
    <AboutSection />
    <ServiceArea />
    <FooterSection />
  </>
);

export default Homepage;

import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar";
import Breadcumb from "../../components/breadcumb";
import WpoFeatures from "../../components/wpofeatures";
// import PricingSection from "../../components/Pricing";
// import Newsletter from "../../components/Newsletter";
import FooterSection from "../../components/Footer";

const PricingPage = () => {
  const { t } = useTranslation();
  return (
    <div className="price-sec">
      <Navbar />
      <Breadcumb
        backgroundClassName="pricing"
        title={t("pricing-page.title")}
        subtitle={t("pricing-page.subtitle")}
      />
      <WpoFeatures />
      {/* <PricingSection />
      <Newsletter /> */}
      <FooterSection />
    </div>
  );
};

export default PricingPage;

export const en = {
  /** Routes */

  "routes.main": "/",
  "routes.home": "/home",
  "routes.about-us": "/about-us",
  "routes.services": "/services",
  "routes.pricing": "/pricing",
  "routes.team": "/team",
  "routes.contacts": "/contacts",
  "routes.not-found": "/not-found",

  /** Page content */
  "menu.main": "Home",
  "menu.home": "Home",
  "menu.about-us": "About Us",
  "menu.services": "Services",
  "menu.pricing": "Pricing",
  "menu.team": "Team",
  "menu.contacts": "Contacts",
  "menu.not-found": "Not Found",

  "home-page.slider.title": "The finest ways to go further",
  "home-page.contact-us": "Contact us",
  "home-page.about-us.title": "About Us",
  "home-page.about-us.description":
    "We pursue the best transport solutions and services with the highest level of competence and commitment to our customers.",
  "home-page.about-us.enumeration.1": "Speed and Commitment",
  "home-page.about-us.enumeration.2": "Mobility and Agility",
  "home-page.about-us.enumeration.3": "Quality and Reliability",
  "home-page.services.title": "Services",
  "home-page.services.subtitle": "We provide the Best",

  "about-us-page.title": "About Us",
  "about-us-page.subtitle": "About",
  "about-us-page.main.title": "Company history",
    "about-us-page.main.description":
    "<p>Criada em 2021, a RMA-Transportes, Lda. inicia o seu percurso em resposta à necessidade crescente de prestação de serviços de transportes de qualidade.</p><p>Com uma equipa altamente qualificada e profissional, apresenta soluções  de transporte personalizadas adequadas às necessidades dos nossos clientes.</p>",
  "about-us-page.mission.title": "Our Mission",
  "about-us-page.mission.subtitle": " ",

  "about-us-page.mission.vision": "Vision",
  "about-us-page.mission.vision.description": "Prestar serviços de excelência a, procurando sempre as melhores soluções de transporte",
  "about-us-page.mission": "Mission",
  "about-us-page.mission.description": "Busca pela inovação e melhoria de processos mantendo níveis de excelência e competitividade.",
  "about-us-page.values": "Values",
  "about-us-page.values.description": "- Rapidez e Compromisso</br>- Mobilidade e Agilidade<br/>- Rigor e Fiabilidade",

  "services.express-transport.title": "Express Transport",
  "services.express-transport.description":
    "Fastest transport services to the mainland Portugal",
  "services.packaging.title": "Removal firm",
  "services.packaging.description":
    "Moving and Transportation Services for Individuals and Businesses with Reliability and Speed",
  "services.warehousing.title": "Storage",
  "services.warehousing.description": "Coming soon...",

  "services-page.title": "Explore the services we have for you",
  "services-page.subtitle": "Services",
  "services-page.contact-us": "Contact us",

"services-page.details.title": "Conheça em detalhe",
  "services-page.details.description":
    "A RMA-Transportes disponibiliza aos seus clientes as soluções de transporte expresso e mudanças adequadas e personalizadas a nível nacional. Sempre em busca na excelência no seus serviços, procuramos ser o seu parceiro de negócio de confiança. Em breve, teremos soluções de logística  e armazenamento.",
  "services-page.details.description.list":
    "<span>Soluções de transporte especialmente desenhadas e adaptadas às necessidades da sua empresa</span><span>Atendimento Personalizado</span><span>Fiabilidade e Rapidez</span>",

  "pricing-page.title": "Budget",
  "pricing-page.subtitle": "Budget",
  "pricing-page.box.title": "What we have best for you",
  "pricing-page.box.subtitle": "Our services",
  "pricing-page.button.contact-us": "Contact us",

  "contact-page.title": "Contacts",
  "contact-page.subtitle": "Contacts",
"contact-page.description":
    "Caso deseje mais informações, por favor contacte-nos preenchendo o nosso formulário.",
  "contact-page.address": "Morada",
  "contact-page.form.name": "Nome",
  "contact-page.form.email": "Email",
  "contact-page.form.subject": "Assunto",
  "contact-page.form.message": "Mensagem",
  "contact-page.form.submit": "Enviar",
  "contact-page.form.subject.select.subject": "Selecione um assunto",
  "contact-page.form.subject.select.general": "Geral",
  "contact-page.form.subject.select.comercial": "Comercial",
  "contact-page.form.subject.select.pricing": "Orçamentação",
  "contact-page.form.subject.select.other.subject": "Outros assuntos",
  "contact-page.form.emailsend.success":
    "<b>Enviado!</b><br/>Recebemos a tua mensagem, prometemos ser breves na resposta.",
  "contact-page.form.emailsend.error":
    "<b>Erro!</b><br/>Ocurreu um erro ao enviar o email. Tente novamente!",
  "contact-page.form.error.name": "Digite o seu nome",
  "contact-page.form.error.email": "Formato de email inválido",
  "contact-page.form.error.message": "Deixa uma mensagem mais extensa",

  "not-found-page.title": "Oops!",
  "not-found-page.subtitle": "Page not found",
  "not-found-page.description":
    "We couldn't find the page's destination.<br/>Let's chart a new course.",
};



export const pt = {
  /** Routes */

  "routes.main": "/",
  "routes.home": "/inicio",
  "routes.about-us": "/quem-somos",
  "routes.services": "/servicos",
  "routes.pricing": "/orcamentos",
  "routes.team": "/equipa",
  "routes.contacts": "/contactos",
  "routes.not-found": "/not-found",

  /** Page content */
  "menu.main": "Início",
  "menu.home": "Início",
  "menu.about-us": "Sobre nós",
  "menu.services": "Serviços",
  "menu.pricing": "Orçamentos",
  "menu.team": "Equipa",
  "menu.contacts": "Contactos",
  "menu.not-found": "Não encontrado",

  "home-page.slider.title":
    "As melhores rotas para ir mais longe.<br/>Go further!",
  "home-page.contact-us": "Contacte-nos",
  "home-page.about-us.title": "Quem somos",
  "home-page.about-us.description":
    "Procuramos as melhores soluções e serviços de transportes com o máximo de  excelência com o máximo de dedicação e empenho para os nossos clientes.",
  "home-page.about-us.enumeration.1": "Rapidez e Compromisso",
  "home-page.about-us.enumeration.2": "Mobilidade e Agilidade",
  "home-page.about-us.enumeration.3": "Rigor e Fiabilidade",
  "home-page.services.title": "Serviços",
  "home-page.services.subtitle": "Fornecemos os Melhores",

  "about-us-page.title": "Sobre nós",
  "about-us-page.subtitle": "Sobre",
  "about-us-page.main.title": "História da empresa",
  "about-us-page.main.description":
    "<p>Criada em 2021, a RMA-Transportes, Lda. inicia o seu percurso em resposta à necessidade crescente de prestação de serviços de transportes de qualidade.</p><p>Com uma equipa altamente qualificada e profissional, apresenta soluções  de transporte personalizadas adequadas às necessidades dos nossos clientes.</p>",
  "about-us-page.mission.title": "Os nossos princípios",
  "about-us-page.mission.subtitle": " ",

  "about-us-page.mission.vision": "Visão",
  "about-us-page.mission.vision.description":
    "Prestar serviços de excelência a, procurando sempre as melhores soluções de transporte",
  "about-us-page.mission": "Missão",
  "about-us-page.mission.description":
    "Busca pela inovação e melhoria de processos mantendo níveis de excelência e competitividade.",
  "about-us-page.values": "Valores",
  "about-us-page.values.description":
    "- Rapidez e Compromisso</br>- Mobilidade e Agilidade<br/>- Rigor e Fiabilidade",

  "services.express-transport.title": "Transportes Expresso",
  "services.express-transport.description":
    "Serviços de transportes urgentes para Portugal continental",
  "services.packaging.title": "Mudanças",
  "services.packaging.description":
    "Serviços de Mudanças e Transportes para Particulares e Empresas com Responsabilidade e Rapidez",
  "services.warehousing.title": "Armazenamento",
  "services.warehousing.description": "Brevemente...",

  "services-page.title": "Conheça os serviços que temos para si",
  "services-page.subtitle": "Serviços",
  "services-page.contact-us": "Contacte-nos",

  "services-page.details.title": "Conheça em detalhe",
  "services-page.details.description":
    "A RMA-Transportes disponibiliza aos seus clientes as soluções de transporte expresso e mudanças adequadas e personalizadas a nível nacional. Sempre em busca na excelência no seus serviços, procuramos ser o seu parceiro de negócio de confiança. Em breve, teremos soluções de logística  e armazenamento.",
  "services-page.details.description.list":
    "<span>Soluções de transporte especialmente desenhadas e adaptadas às necessidades da sua empresa</span><span>Atendimento Personalizado</span><span>Fiabilidade e Rapidez</span>",

  "pricing-page.title": "Orçamentos",
  "pricing-page.subtitle": "Orçamentos",
  "pricing-page.box.title": "O que temos de melhor para si",
  "pricing-page.box.subtitle": "Os nossos serviços",
  "pricing-page.button.contact-us": "Contacte-nos",

  "contact-page.title": "Contactos",
  "contact-page.subtitle": "Contactos",
  "contact-page.description":
    "Caso deseje mais informações, por favor contacte-nos preenchendo o nosso formulário.",
  "contact-page.address": "Morada",
  "contact-page.form.name": "Nome",
  "contact-page.form.email": "Email",
  "contact-page.form.subject": "Assunto",
  "contact-page.form.message": "Mensagem",
  "contact-page.form.submit": "Enviar",
  "contact-page.form.subject.select.subject": "Selecione um assunto",
  "contact-page.form.subject.select.general": "Geral",
  "contact-page.form.subject.select.comercial": "Comercial",
  "contact-page.form.subject.select.pricing": "Orçamentação",
  "contact-page.form.subject.select.other.subject": "Outros assuntos",
  "contact-page.form.emailsend.success":
    "<b>Enviado!</b><br/>Recebemos a tua mensagem, prometemos ser breves na resposta.",
  "contact-page.form.emailsend.error":
    "<b>Erro!</b><br/>Ocurreu um erro ao enviar o email. Tente novamente!",
  "contact-page.form.error.name": "Digite o seu nome",
  "contact-page.form.error.email": "Formato de email inválido",
  "contact-page.form.error.message": "Deixa uma mensagem mais extensa",

  "not-found-page.title": "Oops!",
  "not-found-page.subtitle": "Página não encontrada",
  "not-found-page.description":
    "Não encontramos o destino da página.<br/>Vamos estabelecer nova rota.",
};

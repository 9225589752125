import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import NavigationLink from "../../i18n/components/NavigationLink";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.css";

const SimpleSlider = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    arrows: false,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };
  return (
    <section className="hero hero-slider-wrapper hero-style-1">
      <div className="hero-slider">
        <Slider {...settings}>
          <div className="slide1 slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-10 slide-caption">
                  <h2>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(`home-page.slider.title`),
                      }}
                    />
                  </h2>
                  <div className="btns">
                    <div className="btn-style">
                      <NavigationLink to="ContactPage">{t(`home-page.contact-us`)}</NavigationLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default SimpleSlider;

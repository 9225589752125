import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar";
import Breadcumb from "../../components/breadcumb";
import Contactpage from "../../components/Contactpage";
import FooterSection from "../../components/Footer";

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <Breadcumb
        backgroundClassName="contact"
        title={t("contact-page.title")}
        subtitle={t("contact-page.subtitle")}
      />
      <Contactpage />
      <FooterSection />
    </div>
  );
};

export default ContactPage;

import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar";
import Breadcumb from "../../components/breadcumb";
import NotFoundSection from "../../components/NotFoundSection";
import FooterSection from "../../components/Footer";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar disableScrollEffect={true} />
      <Breadcumb
        backgroundClassName="not-found"
        title={t("not-found-page.title")}
        subtitle={t("not-found-page.subtitle")}
      />
      <NotFoundSection />
      <FooterSection />
    </div>
  );
};

export default NotFound;

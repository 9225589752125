export const AppRoute = {
  MainPage: "routes.main",
  HomePage: "routes.home",
  AboutPage: "routes.about-us",
  ServiceSingle: "routes.services",
  PricingPage: "routes.pricing",
  TeamPage: "routes.team",
  ContactPage: "routes.contacts",
  NotFoundPage: "routes.not-found",
};

import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";
const Mission1 = () => {
  const { t } = useTranslation();
  return (
    <div className="service-style-3">
      <div className="container">
        <div className="col-l2">
          <div className="wpo-section-title text-center">
            <span>{t("about-us-page.mission.subtitle")}</span>
            <h2>{t("about-us-page.mission.title")}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-truck-2" />
              </div>
              <div className="service-text">
                <h3>{t("about-us-page.mission.vision")}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(`about-us-page.mission.vision.description`),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-truck-2"></i>
              </div>
              <div className="service-text">
                <h3>{t("about-us-page.mission")}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(`about-us-page.mission.description`),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-truck-2"></i>
              </div>
              <div className="service-text">
                <h3>{t("about-us-page.values")}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(`about-us-page.values.description`),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission1;

import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AppLanguages } from "../../const";

const getEnumKeyByEnumValue = (myEnum, enumValue) => {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : "";
};

const IntlProviderComponent = ({
  children,
  appStrings,
  defaultLanguage,
  languages,
}) => {
  const [t, i18n] = useTranslation();
  const { pathname } = useLocation();
  const regexToMatch = new RegExp("/[a-zA-Z]{2}/", "gi");
  const Textmatch = pathname.match(regexToMatch);

  const { lang = defaultLanguage || AppLanguages.English } =
    Textmatch && Textmatch.length > 0
      ? { lang: Textmatch[0].replaceAll("/", "") }
      : {};

  useEffect(() => {
    i18n.changeLanguage(lang, () => {
      document.documentElement.lang = lang;
    });
  }, [lang, i18n]);

  if (!pathname.includes(`${lang}`)) {
    const isBrowserLanguageExistInApp = getEnumKeyByEnumValue(
      AppLanguages,
      languages
    );
    let defaultLang = i18n.resolvedLanguage; //lang;

    if (isBrowserLanguageExistInApp in AppLanguages) {
      defaultLang = languages;
    }
    return <Navigate to={`/${defaultLang}/`} />;
  }

  return (
    <IntlProvider locale={lang} messages={appStrings[lang]}>
      {children}
    </IntlProvider>
  );
};

export const LocalizedRouter = ({
  children,
  appStrings,
  defaultLanguage,
  languages,
}) => (
  <Router>
    <IntlProviderComponent
      appStrings={appStrings}
      defaultLanguage={defaultLanguage}
      languages={languages}
    >
      {children}
    </IntlProviderComponent>
  </Router>
);
// <Route path="/:lang([a-zA-Z]{2})">
// </Route>
// {({ match, location }: { match: PathMatch, location: Location }) => {
// 	// /**
// 	//  * Get current language
// 	//  * Set default locale to en if base path is used without a language
// 	//  */
// 	const params = match ? match.params : {};
// 	const { lang = defaultLanguage || AppLanguage.English } = params;

// 	// /**
// 	//  * If language is not in route path, redirect to language root
// 	//  */
// 	const { pathname } = location;
// 	if (!pathname.includes(`/${lang}/`)) {
// 		//  <Redirect to={`/${lang}/`} />;
// 		return <Route path="/" element={<Navigate replace to={`/${lang}/`} />} />
// 	}

// 	// /**
// 	//  * Return Intl provider with default language set
// 	//  */
// }}

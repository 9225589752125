import { useIntl } from "react-intl";
import { AppRoute } from "./../../const";
import { NavLink } from "react-router-dom";

const NavigationLink = ({ to, children, className }) => {
  const { formatMessage, locale } = useIntl();

  function localizeRouteKey(path) {
    return `/${locale}${formatMessage({ id: path })}`;
  }

  return (
    <NavLink key={to} to={localizeRouteKey(AppRoute[to])} className={className}>
      {typeof children !== "undefined" ? children : ""}
    </NavLink>
  );
};
export default NavigationLink;

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import NavigationLink from "../../i18n/components/NavigationLink";
import "./style.css";

const Breadcumb = ({ backgroundClassName = "", title = "", subtitle = "" }) => {
  const { t } = useTranslation();

  const breadcumbStyle = classNames(`breadcumb-area`, {
    [`about-page`]: backgroundClassName === "about",
    [`contact-page`]: backgroundClassName === "contact",
    [`services-page`]: backgroundClassName === "services",
    [`pricing-page`]: backgroundClassName === "pricing",
    [`not-found-page`]: backgroundClassName === "not-found",
  });

  return (
    <div className={breadcumbStyle}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcumb-wrap text-center">
              <h1>{title}</h1>
              <ul>
                <li>
                  <NavigationLink to="MainPage">
                    {t("menu.home")}
                  </NavigationLink>
                </li>
                {!!subtitle && (
                  <li>
                    <span>{subtitle}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcumb;

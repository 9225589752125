import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const AboutSection2 = () => {
  const { t } = useTranslation();
  return (
    <div className="wpo-about-style-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-6  offset-lg-6 about-wrap">
            <div className="wpo-about-content">
              <div className="wpo-about-icon">
                <i className="fi flaticon-travel"></i>
              </div>
              <h2>{t("about-us-page.main.title")}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`about-us-page.main.description`),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection2;

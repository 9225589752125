import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ScrollTop from "../ScrollTop";
import Homepage from "../HomePage";
import Aboutpage from "../AboutPage";
import ServiceSingle from "../ServiceSingle";
import PricingPage from "../PricingPage";
import TeamPage from "../TeamPage";
import ContactPage from "../ContactPage";
import NotFoundPage from "../NotFound";
import { AppRoute } from "./../../const";
import { appStrings, LocalizedRouter, LocalizedRoutes } from "./../../i18n";

const AllRoute = () => {
  const [i18n] = useTranslation();

  return (
    <LocalizedRouter
      defaultLanguage={navigator.language}
      languages={i18n.languages}
      appStrings={appStrings}
    >
      <ScrollTop />
      <LocalizedRoutes>
        <Route exact path={AppRoute.MainPage} element={<Homepage />} />
        <Route path={AppRoute.HomePage} element={<Homepage />} />
        <Route path={AppRoute.AboutPage} element={<Aboutpage />} />
        <Route path={AppRoute.ServiceSingle} element={<ServiceSingle />} />
        <Route path={AppRoute.PricingPage} element={<PricingPage />} />
        <Route path={AppRoute.TeamPage} element={<TeamPage />} />
        <Route path={AppRoute.ContactPage} element={<ContactPage />} />
        <Route path={AppRoute.NotFoundPage} element={<NotFoundPage />} />
        <Route
          path="*"
          element={<Navigate to={AppRoute.NotFoundPage} replace />}
        />
      </LocalizedRoutes>
    </LocalizedRouter>
  );
};

export default AllRoute;

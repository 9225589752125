import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const ServiceArea = () => {
  const { t } = useTranslation();
  return (
    <div className="wpo-service-s2">
      <div className="container">
        <div className="col-l2">
          <div className="wpo-section-title text-center">
            <span>{t("services-page.subtitle")}</span>
            <h2>{t("services-page.title")}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item">
              <div className="wpo-service-single">
                <div className="wpo-service-icon">
                  <i className="fi flaticon-truck-2"></i>
                </div>
                <h2>{t("services.express-transport.title")}</h2>
                <p>{t("services.express-transport.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item">
              <div className="wpo-service-single">
                <div className="wpo-service-icon">
                  <i className="flaticon-truck-2"></i>
                </div>
                <h2>{t("services.packaging.title")}</h2>
                <p>{t("services.packaging.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-service-item">
              <div className="wpo-service-single">
                <div className="wpo-service-icon">
                  <i className="flaticon-trolley"></i>
                </div>
                <h2>{t("services.warehousing.title")}</h2>
                <p>{t("services.warehousing.description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceArea;

export const menus = [
  {
    id: 1,
    title: "menu.home",
    link: "HomePage",
  },
  {
    id: 2,
    title: "menu.about-us",
    link: "AboutPage",
  },
  {
    id: 3,
    title: "menu.services",
    link: "ServiceSingle",
  },
  {
    id: 4,
    title: "menu.pricing",
    link: "PricingPage",
  },
  {
    id: 4,
    title: "menu.contacts",
    link: "ContactPage",
  },
];

import React from "react";
import { AppLanguages } from "./../../const";
import { NavLink, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";

import { appStrings } from "../index";

import "./style.css";

export const LanguageSwitcher = () => {
  const [t, i18n] = useTranslation();

  const { pathname } = useLocation();
  const { messages } = useIntl();

  function getMatchingRoute(language) {
    /**
     * Get the key of the route the user is currently on
     */
    const route = pathname.substring(3); // remove local part '/en' from the pathname /en/contact
    const routeKey =
      Object.keys(messages).find((key) => messages[key] === route) ?? "English";
    /**
     * Find the matching route for the new language
     */
    const matchingRoute = appStrings[language][routeKey];

    /**
     * Return localized route
     */
    return `/${language}` + matchingRoute;
  }

  const handleOnClickLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      {Object.keys(AppLanguages).map((lang) => {
        if (i18n.language === AppLanguages[lang]) {
          return null;
        }
        return (
          <li className="active" key={lang}>
            <NavLink
              onClick={() => handleOnClickLanguage(AppLanguages[lang])}
              to={getMatchingRoute(AppLanguages[lang])}
            >
              <span className={`flag-icon flag-${AppLanguages[lang]}`} />
              &nbsp;
            </NavLink>
          </li>
        );
      })}
    </>
  );
};

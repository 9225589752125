import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar";
import Breadcumb from "../../components/breadcumb";
import ServiceSection from "../../components/ServiceSection";
import ServiceArea3 from "../../components/ServiceArea3";
import FooterSection from "../../components/Footer";

const ServiceSingle = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <Breadcumb
        backgroundClassName="services"
        title={t("services-page.title")}
        subtitle={t("services-page.subtitle")}
      />
      <ServiceSection />

      <ServiceArea3 />

      <FooterSection />
    </div>
  );
};

export default ServiceSingle;

import React, { useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";

import { LanguageSwitcher } from "../../i18n";
import NavigationLink from "../../i18n/components/NavigationLink";
import "./style.css";

import "react-perfect-scrollbar/dist/css/styles.css";

const MobileMenu = ({ menus = [] }) => {
  const { t } = useTranslation();
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [isOpen, setIsOpen] = useState(0);

  const menuHandler = () => setIsMenuShow(!isMenuShow);

  return (
    <div>
      <PerfectScrollbar>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

          <ul className="responsivemenu">
            {menus.map((item, index) => {
              return (
                <li key={`mobile-menu-${item.id}-${index}`}>
                  {item.submenu ? (
                    <p onClick={setIsOpen(item.id === isOpen ? 0 : item.id)}>
                      {t(item.title)}
                      {item.submenu && (
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      )}
                    </p>
                  ) : (
                    <NavigationLink to={item.link}>
                      {t(item.title)}
                    </NavigationLink>
                  )}
                  {item.submenu && (
                    <Collapse isOpen={item.id === isOpen}>
                      <Card>
                        <CardBody>
                          <ul>
                            {item.submenu.map((submenu) => (
                              <li key={submenu.id}>
                                <NavigationLink
                                  className="active"
                                  to={submenu.link}
                                >
                                  {t(submenu.title)}
                                </NavigationLink>
                              </li>
                            ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Collapse>
                  )}
                </li>
              );
            })}
            <LanguageSwitcher />
          </ul>
        </div>
      </PerfectScrollbar>
      <div className="showmenu" onClick={menuHandler}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
      {isMenuShow && (
        <div className="mobileMenuBackground" onClick={menuHandler} />
      )}
    </div>
  );
};

export default MobileMenu;

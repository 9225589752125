import React from "react";
import { useTranslation } from "react-i18next";
import NavigationLink from "../../i18n/components/NavigationLink";
import "./style.css";

const ServiceSection = () => {
  const { t } = useTranslation();
  return (
    <div className="section-area section-style-2 section-style-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-12 col-d c-pd">
            <div className="section-wrap">
              <div className="section-item-2">
                <div className="section-icon">
                  <i className="fi flaticon-truck-2"></i>
                </div>
                <div className="section-content">
                  <p>
                    <a href="ocean.html">
                      {t("services.express-transport.title")}
                    </a>
                  </p>
                  <span>{t("services.express-transport.description")}</span>
                </div>
              </div>
              <div className="section-item-2">
                <div className="section-icon">
                  <i className="fi flaticon-truck-2"></i>
                </div>
                <div className="section-content">
                  <p>
                    <a href="road.html">{t("services.packaging.title")}</a>
                  </p>
                  <span>{t("services.packaging.description")}</span>
                </div>
              </div>
              <div className="section-item-2">
                <div className="section-icon">
                  <i className="fi flaticon-trolley"></i>
                </div>
                <div className="section-content">
                  <p>
                    <a href="Freight.html">{t("services.warehousing.title")}</a>
                  </p>
                  <span>{t("services.warehousing.description")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="tr-wrap">
              <div className="t-text">
                <h2>{t(`services-page.details.title`)}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(`services-page.details.description`),
                  }}
                />
              </div>
              <div className="tr-s">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(`services-page.details.description.list`),
                  }}
                />
              </div>
              <div className="btns text-center">
                <div className="btn-style">
                  <NavigationLink to={"ContactPage"}>
                    {t("services-page.contact-us")}
                  </NavigationLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;

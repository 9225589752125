import React from "react";
import { useTranslation } from "react-i18next";

import ContactForm2 from "../CommentForm2";

import "./style.css";

const Contactpage = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-page-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="contact-page-item">
              <h2>{t("contact-page.title")}</h2>
              <p>{t("contact-page.description")}</p>
              <div className="adress">
                <h3>{t("contact-page.address")}</h3>
                <span>
                  Rua de Santarém, 113 - 1° Tras.
                  <br />
                  4400-292 Vila Nova De Gaia
                  <br />
                  Portugal
                </span>
              </div>
              <div className="email">
                <h3>Email</h3>
                <span>
                  geral@<span className="hidden-email">null</span>
                  rma-transportes.pt
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="contact-area contact-area-2 contact-area-3">
              <ContactForm2 />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="contact-map">
              <iframe
                title="db"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d186.83225358338942!2d-8.357731220571559!3d41.47574720133493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24fa60c9b0e36f%3A0x1bc55396c94d7347!2sR.%20de%20Santar%C3%A9m%20113!5e0!3m2!1sen!2spt!4v1694539769378!5m2!1sen!2spt"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactpage;

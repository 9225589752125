import React, { useEffect } from "react";
import { isWebpSupported } from "react-image-webp/dist/utils";
import AllRoute from "../router";
import "./App.css";

const App = () => {
  useEffect(() => {
    document.body.classList.add(
      `${isWebpSupported() ? "webp-supported" : "no-webp-supported"}`
    );
  }, []);

  return (
    <div className="App">
      <AllRoute />
    </div>
  );
};
export default App;

import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";
const ServiceArea3 = () => {
  const { t } = useTranslation();
  return (
    <div className="service-style-3">
      <div className="container">
        <div className="col-l2">
          <div className="wpo-section-title text-center">
            <span>{t("services-page.title")}</span>
            <h2>{t("services-page.subtitle")}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-truck-2" />
              </div>
              <div className="service-text">
                <h3>{t("services.express-transport.title")}</h3>
                <p>{t("services.express-transport.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-truck-2"></i>
              </div>
              <div className="service-text">
                <h3>{t("services.packaging.title")}</h3>
                <p>{t("services.packaging.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-trolley"></i>
              </div>
              <div className="service-text">
                <h3>{t("services.warehousing.title")}</h3>
                <p>{t("services.warehousing.description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceArea3;

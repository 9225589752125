import React from "react";
import { useTranslation } from "react-i18next";

import logoBig from "../../images/logo/logo-orange-blue.png";

import "./style.css";

const AboutSection = () => {
  const { t } = useTranslation();
  return (
    <div className="wpo-about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="wpo-about-img">
              <img src={logoBig} alt="RMA - Transportes" />
            </div>
          </div>
          <div className="col-lg-6 sec-p">
            <div className="wpo-about-content">
              <div className="wpo-about-icon">
                <i className="fi flaticon-travel"></i>
              </div>
              <h2>{t("home-page.about-us.title")}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`home-page.about-us.description`),
                }}
              />

              <span>{t("home-page.about-us.enumeration.1")}</span>
              <span>{t("home-page.about-us.enumeration.2")}</span>
              <span>{t("home-page.about-us.enumeration.3")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;

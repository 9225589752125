import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import NavigationLink from "../../i18n/components/NavigationLink";
import { menus } from "../../const";

import logoLivroReclamacoes from "../../images/logo-livro-reclamacoes.png";
import logoBig from "../../images/logo/logo-big.png";
import "./style.css";

const FooterSection = () => {
  const { t } = useTranslation();
  return (
    <div className="wpo-footer-area">
      <div className="wpo-footer-top">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-3 col-md-6 col-sm-6 footer-t">
              <div className="wpo-footer-logo">
                <img src={logoBig} alt="RMA - Transportes" />
              </div>
              <p>Siga-nos nas redes sociais:</p>
              <div className="social">
                <ul className="d-flex">
                  <li>
                    <Link to="/">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 footer-t text-center">
              <div className="footer-link">
                <h3>Quick Link</h3>
                <ul>
                  {menus.map((item, index) => (
                    <li key={`menu-${item.id}-${index}`}>
                      <NavigationLink to={item.link}>
                        {t(item.title)}
                      </NavigationLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-12 text-center">
              <div className="footer-link">
                <a
                  href="https://www.livroreclamacoes.pt/Inicio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={logoLivroReclamacoes} alt="Livro de reclamações" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-footer-bottom">
        <div className="container">
          <div className="wpo-footer-bottom-content">
            <div className="row">
              <div className="col-12">
                <span>© Copyrights 2023. All Rights Reserved.</span>
                <div>
                  <small>
                    <a href="https://www.freepik.com/free-photo/two-cardboard-boxes-cart_955456.htm#page=2&query=shipping%20container%20mockup&position=4&from_view=search&track=ais">
                      Image by d3images
                    </a>
                    on Freepik
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
